import React, { useEffect } from "react"
import Layout from '../components/layout'
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from 'gatsby'

const SellThroughCalculator = () => {

  useEffect(() => {
    if (document) {
      const body = document.querySelector('#calc-area');
        if (body) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.innerHTML = `var widgetOptions313102 = { bg_color: "transparent" }; (function() { var a = document.createElement("script"), h = "head"; a.async = true; a.src = (document.location.protocol == "https:" ? "https:" : "http:") + "//ucalc.pro/api/widget.js?id=313102&t="+Math.floor(new Date()/18e5); document.getElementsByTagName(h)[0].appendChild(a) })();`
            body.appendChild(script);
        }      
    }
  }, [])

  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          pages {
            sellThroughCalculator {
              title
              description
              url
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <Helmet>
        {/* Meta */}
          <html lang="en" />
          <title>{data.site.siteMetadata.pages.sellThroughCalculator.title}</title>
          <meta charSet="utf-8" />
          <meta name="title" content={`Features - ${data.site.siteMetadata.title}`}/>
          <meta name="description" content={data.site.siteMetadata.pages.sellThroughCalculator.description} /> 
          <link rel="canonical" href={data.site.siteMetadata.pages.sellThroughCalculator.url} />
      </Helmet>
      <Layout>
        <div id="calc-area">
          <h1 className="mt-1 text-3xl font-extrabold text-gray-900 sm:text-4xl sm:tracking-tight lg:text-5xl">
            <span className="text-peach-500 md:bg-clip-text md:text-transparent md:bg-gradient-to-r md:to-peach-500 md:from-indigo-700">
              Sell through calculator
            </span>
          </h1>
          <div className="uCalc_313102"></div>
        </div>
      </Layout>  
    </>
  )
}

export default SellThroughCalculator
